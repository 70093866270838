import { useNavigate } from "react-router-dom";
import {useState} from "react"
import OrderPageService from "./Services/OrderPageService"

function CheckEmail() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const onClickHandler = async () => {
        if (await OrderPageService.isValidEmail(email)) {
            localStorage.setItem("email",email);
            navigate(process.env.REACT_APP_PublicPath +"/order");
        }
        else {
            navigate(process.env.REACT_APP_PublicPath +"/NotApproved");
        }
    }

    return (
        <div>
            <img src={require('./logo.png')}></img>
            <div className="card relative w-32">
                <div className="container ">
          
                    Welcome to the Xceed Solutions ordering platform.<br></br>
                    <br></br>
                    <span className="widget-title">Please Enter your Email</span>
                    <br></br>
                    <input 
                    placeholder="Email" 
                    className="m-1"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    /><br></br>
                    <button className="absolute bottom-3 right-2 h-22 w-32" onClick={() =>onClickHandler()}>Submit</button>
                </div>
            </div>
      </div>
    );
  }
  
  export default CheckEmail;
  