import {OrderData} from "../Classes/OrderData"
import apiClient from './api'
class OrderPageService {
    isValidEmail = async(emailAddress: string) => {
        try {
            const response = await apiClient.get(`/OrderPortal/CheckEmailExists/`+emailAddress);
            return response.data.response;
        }
        catch (err) {
            return false;
        }
    }

    submitOrder = async(orderInfo: any) => {
        try {
            const response = await apiClient.post('/OrderPortal/CreatePortalOrder', orderInfo)
            return response.data.response
          } catch (err) {
            console.log(err)
            return false;
          }
    }

    getOrderInfo = async (emailAddress: string) => {
        try {
            let response = await apiClient.get(`/OrderPortal/GetEmailProfile/`+emailAddress);
            return response.data;
        }
        catch (err) {
            let emptyData : OrderData= {
                email: emailAddress,
                name: "",
                phone: "", 
                address1: "",
                city: "",
                state: "",
                zip: "",
                quantity: ""
            }
            return emptyData;
        }
    }
}


export default new OrderPageService()