import { Route, Routes } from "react-router-dom";
import React from 'react';
import logo from './logo.svg';
import CheckEmail from './CheckEmail';
import NotApproved from "./NotApproved";
import Order from "./Order"
import Success from "./Success"
import './App.css';

function App() {
  return (
    <Routes>
    {/* Unprotected Routes */}
    <Route path={process.env.REACT_APP_PublicPath +"/"} element={<CheckEmail />} />
    <Route path={process.env.REACT_APP_PublicPath +"/notapproved"} element={<NotApproved />} />
    <Route path={process.env.REACT_APP_PublicPath +"/order"} element={<Order />} />
    <Route path={process.env.REACT_APP_PublicPath +"/success"} element={<Success />} />
    </Routes>
    
  );
}

export default App;

