import { useNavigate } from "react-router-dom";
function Success() {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate("/", {replace:true} );
    }
    return (
        <div>
            <img src={require('./logo.png')}></img>
            <div className="card relative h-32 w-32">
                <div className="container">
          
                    Your order went through successfully!<br></br>
                    <button className="absolute bottom-3 right-2 h-22 w-32" onClick={() =>onClickHandler()}>Order Again</button>
                </div>
            </div>
      </div>
    );
  }
  
  export default Success;