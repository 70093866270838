import { useNavigate } from "react-router-dom";
import {useState, useEffect} from "react"
import OrderPageService from "./Services/OrderPageService"
import {OrderData} from "./Classes/OrderData"
function Order() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [quantity, setQuantity] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        onLoad();
    }, [])

    const onLoad = async () => {
        let userEmail = localStorage.getItem("email");
        if (userEmail != null) {
            let defaultData : OrderData = await OrderPageService.getOrderInfo(userEmail);
            setEmail(defaultData.email)
            setName(defaultData.name);
            setPhone(defaultData.phone);
            setAddress1(defaultData.address1);
            setCity(defaultData.city);
            setZip(defaultData.zip);
            setState(defaultData.state);
            setQuantity(defaultData.quantity);
        }
        
    }

    const isNullOrEmpty = (item:string) => {
        if (item===null||item===""||item===undefined) {
            return true;
        }
        return false;
    }

    const onClickHandler = async () => {
        if (isNullOrEmpty(name)||isNullOrEmpty(email)||isNullOrEmpty(phone)||isNullOrEmpty(address1)||isNullOrEmpty(city)||isNullOrEmpty(state)||isNullOrEmpty(zip)||isNullOrEmpty(quantity)) {
            alert("Please fill out all fields");
        }
        else if (Number(quantity) < 1 || Number(quantity) > 100000) {
            alert("Please enter a quantity between 1 and 100000");
        }
        else {
            let postData : OrderData= {
                email: email,
                name: name,
                phone: phone, 
                address1: address1,
                city: city,
                state: state,
                zip: zip,
                quantity: quantity
            }
            if (await OrderPageService.submitOrder(postData)) {
                navigate(process.env.REACT_APP_PublicPath +"/success", {replace:true} );
            }
            else {
                alert("There was a problem submitting your order.  Please try again later.")
            }
        }
    }
    return (
        <div>
            <img src={require('./logo.png')}></img>
            <div className="card relative w-32">
                <div className="container ">
          
                    Welcome to the Xceed Solutions ordering platform.<br></br>
                    <br></br>
                    <span className="widget-title">ORDER INFORMATION</span>
                    <br></br>
                    <input placeholder="Name" className="m-1" type="text" value={name} onChange={(e) => setName(e.target.value)}/> <br></br>
                    <input placeholder="Email" className="m-1" type="text" value={email} onChange={(e) => setEmail(e.target.value)} disabled={true}/><br></br>
                    <input placeholder="Phone" className="m-1" type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/><br></br>
                    <input placeholder="Address" className="m-1" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)}/> <br></br>
                    <input placeholder="City" className="m-1" type="text" value={city} onChange={(e) => setCity(e.target.value)}/> <br></br>
                    <input placeholder="State" className="m-1" type="text" value={state} onChange={(e) => setState(e.target.value)}/><br></br>
                    <input placeholder="Zip" className="m-1" type="text" value={zip} onChange={(e) => setZip(e.target.value)}/> <br></br>
                    <input placeholder="Number of Cases" className="m-1" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)}/> <br></br>

                    <button className="absolute bottom-3 right-2 h-22 w-32" onClick={() =>onClickHandler()}>Submit</button>
                </div>
            </div>
      </div>
    );
  }
  
  export default Order;